.MainContainer {
width: 20%;
  background-color: #ffffff;
  overflow: scroll;
  height: 98vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-right: 1px solid #dfdfdf;
}


.flex{
  display: flex;
  padding: 10px 17px;
  align-items: center;
  justify-content: space-between;
}/* -----------------RiHeading---------------- */
.TextHeading {
  margin-top: 25px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  background-color: #138496;
  padding: 8px;
  text-align: center;
  border-radius: 8px;
}
/* -----------------RiHeading---------------- */

/* -----------------SideBar---------------- */
.sideBar_UserName {
  margin: 25px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  background-color: #3f3e45;
  padding: 8px;
  text-align: center;
  border-radius: 8px;
}
.sideBar_SideBarContainer {
  display: flex;
  padding: 10px 17px;
  align-items: center;
  /* border-bottom: 1px solid lightgrey; */
  margin: 3px;
}
.sideBar_SideBarIcon {
  height: 25px;
  width: 25px;
}
.sideBar_SideBarTextContainer {
  font-size: 16px;
  color: gray;
  margin: 2px 8px;
 /* font-weight: 500; */
}

.sideBar_SideBarTextContainer:hover
{
  color: mediumblue;
}
.active_link {
  font-weight: 500;
color: #138496;
}
.link {
  text-decoration: none;
  margin-left: 16px !important;
  /* color: #138496; */
 

}

.sideBar_SideBarContainer_SubMenu{
  
  margin: 5px;
  color: black;
}
.link_SubMenu{
  margin-left: 10%;
  padding: 10px;
}
.removeDeco{
  text-decoration: none;
}
.sideBar_logo img{
  height: 45px;
  width: 80%;
  padding-top: 35px;
  margin-left: 10px;
  margin-bottom: 10px;
}


.sideBar_LogoutBtn{
  box-shadow: none !important;
  margin-left: -15px !important;
}
.sideBar_SideBarTextContainer_a{
  margin-left: -16px !important;
}
.ClientShowHeader{
  padding:10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ClientUp{
cursor:pointer;
color: #138496;
font-weight: 500;
margin-left: 22px;
}
/* -----------------SideBar---------------- */

/* -----------------UserProfile---------------- */
.UserProfile_Container {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  align-items: center;
}
.UserProfile_ThreeDotIcon {
  padding-top: 25px;
  color: gray;
}
.UserProfile_ProfileContainer {
  text-align: center;
  margin: 30px 0px;
}
.UserProfile_UserIcon {
  height: 70px;
  width: 70px;
}
.sideBar_UserProfile{
  margin-top: 25px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  background-color: #3f3e45;
  padding: 8px;
  text-align: center;
  border-radius: 8px;
}
/* -----------------UserProfile---------------- */
.UserProfileContainer{
  flex: 1;
}
