

/* Style the list */
.breadcrumb {
    padding: 8px 10px;
    list-style: none;
   
  }
  
  /* Display list items side by side */
  .breadcrumb_li {
    display: inline;
    font-size: 15px;
    list-style: none;
  }
  
  /* Add a slash symbol (>>) before/behind each list item */
  .breadcrumb_li+li:before {
    color: #000;
    content: ">>";
    margin: 3px;
    font-size: 12px;
    padding: 4px;
  }
  
  /* Add a color to all links inside the list */
  .breadcrumb_li_a {
    color: #000;
    text-decoration: none;
  }
  
  /* Add a color on mouse-over */
  .breadcrumb_li_a:hover {
    color: red;
    text-decoration: underline;
  }
  .mainBreadcrumbs{
      display: flex;
      align-items: center;
  }
  .MainLink{
      text-decoration: none;
  }
  .slash{
      display: flex;
      align-items: center;
  }