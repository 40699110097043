.lableDesign {
    font-weight: 700;
    color: #292e42;
    margin-left: 10px;
  }
  .inputContainer {
    display: flex;
    flex-direction: column;
  }
  .inputArea:focus {
    outline: none;
  }
  .inputArea {
    padding: 12px;
    border: 1px solid #ece9e9;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px 10px;
   
  }
  .inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }  
  .inputContainer_skill{
    display: flex;
    flex-direction: column;
    
  }
  .ColumnWiseInput{
    flex-direction: row;
    align-items: center;
    display: grid;
  }

  .ColumnWiseInput .inputArea {
    padding: 12px;
    border: 0.5px solid #000;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px 10px;
    width: 230px;
}
.inputArea_dropdown {
  border: 1px solid #ece9e9;
  border-radius: 8px;
  height: 45px;
  font-size: 18px;
  outline: none;
  padding: 11px 12px;
  cursor: pointer;
  background-color: white;
  margin: 10px 0px;
  width: 92%;
  color: #000;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 10.127L12 18.127L20 10.127H4Z" fill="%238E8E93"/></svg>');
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 98%;
}  