.MainContainer {
    margin: 20px;
    margin-left: 20%;
}
.TextEditor {
width: 100%;
margin-left: 10px; 
margin-top: 10px;
margin-bottom: 20px;
border:1px;
}
.inputContainer{
    margin-top: 10px;
}
.label{
    font-weight: 600;
    margin-left: 10px;
}
.question{
    font-weight: 400;
    width: 100%;
   margin-left: 5px;
    outline: none;
    border-radius: 2px;
    height: 30px;
    margin-top: 10px;
}
.Button{
    background-color:#a76b50;
   height: 20px;
    width: 40px;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-left: 390px;
    margin-top: -34px;
}
.Button:hover{
    cursor: pointer;
    background-color: white;
    border: 1px solid #a76b50;
    color: #a76b50;
}

.typeDropdown{
    padding: 3px;
}
.btnDiv{
margin-left:0px ;
}