.MainContainer{
 display: inline-block;
 width: 80%;
justify-content: space-between; 
align-items: center;
padding-top: 15px;
overflow: scroll;
}

.dasboard_button{
    padding: 10px;
    margin: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;

}

.dasboard_link{
    border: 1px solid;
    padding: 10px;
    margin: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: #000;
    margin-top: 25px; 
}

.dasboard_link :hover{
    color: #000;
    background-color: #ccc;
}

.signCount{
    padding-left: 40px;
}