.MainContainer{
    flex:4;
    background-color:#f5f7fb;
    padding: 8px 40px;  
}
.lableDesign {
  font-weight: 800;
  color: #292e42;
}
.inputContainer {
  display: flex;
  flex-direction: column;
}
.inputArea:focus {
  outline: none;
}
.inputArea {
  padding: 12px;
  border: 1px solid #ece9e9;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px 0px;
}
.buttonBox {
    background-color: #7e7c7c;
    color: white;
    border-radius: 6px;
    display: flex;
    margin: auto;
    padding: 10px 20px;
    margin-top: 25px;
  border: none;
  }