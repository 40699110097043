.MainContainer{
  padding: 0 20px;  
  flex:4;
 background-color:#f5f7fb;
 /* padding: 8px 12px; */
 overflow: scroll;
  height: 97vh;
  
  
} 

.input_box{
margin-top: 30px;
background-color:#f5f7fb ;     
} 

.id_input{
    font-family: "PT Sans", sans-serif;
    font-size: 16px;
    display: block;
    padding: 5px 5px;
    width: 100%;
 }


 .form_data {
      pointer-events: none;
      opacity: 1;
    }


.nameInputDiv{
  display: flex;
  padding: 10px , 20px;
  width : 100%

 }  

.userdetail_heading{
  font-size: 28px;
  font-weight: 600;
  margin-left: 12px;
}

.postARequirementLiveButtons{
    margin: 1rem;
    text-align: center;
}

.approve{
    background-color:lightgreen;
    color:white;
    font-size: 20px;
    border: none;
    outline:none;
    cursor: pointer;
    padding:8px 0px;
    border-radius: 8px;
    /*margin: 1rem;
    margin-left: -84px;*/
    width: 110px;
  }

.disapprove{
    background-color:tomato;
    color:white;
    font-size: 20px;
    border: none;
    outline:none;
    cursor: pointer;
    padding:8px 0px;
    border-radius: 8px;
    margin: 1rem;
    width: 110px;
  }

/* .cityStateContainer{
  display: flex;
}
.cityStateContainer input{
 margin-right: 20px; 
 margin-left: 20px;
} */


.display {
 display: flex;
  width: 100%;
  justify-content: space-between; 
  align-items: center;
  padding-top: 10px;
  /*margin-top: 15px;
   font-weight: 500; */
  
}
.detail_heading {
  font-weight: 700;
  padding: 10px 0;
  margin-top: 21px;
  font-size : 22px;
}
.image_url{
  text-decoration: none;
}
.titleBlock{
  color: #292e42;
  margin-left: 10px;
  margin-bottom: 25px; 
  font-size: 15px;
}

.titleHead{
  font-size: 18px; 
  font-weight: bold
}
.titleText{
  font-size: 16px;
  font-weight: 400;
  word-wrap:break-all;
}
.title{
  
  font-size: 15px; 
  width: inherit;
  font-weight: 500;
  line-height: 2;
}


.Company_Logo{
  font-weight:700;
  
}
.Company_Logodiv{
  margin-top: 10px;
  margin-left:5px ;
}
.title_text {
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  font-weight:300; 

}

.value{
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  font-weight: 200px;
}