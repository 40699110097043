.MainContainer {
  flex: 4;
  background-color: #f5f7fb;
  padding: 8px 12px;
  width: 93%;
  overflow: hidden;
}
.CardContainer {
  display: flex;
  flex-direction: row;
  margin: auto;
}

.table_heading {
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  background-color: #c0bdbd;
  padding: 4px;
}
.table_td {
  text-align: center;
  padding: 7px;
  font-size: 15px;
  background-color: #ffffff;
  border-bottom: 0.5px solid #292929;
}
.table_container {
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
  margin: auto;
  overflow: scroll;
  height: 75vh;
  padding-top: 20px;
}
.date_input {
  margin: 10px;
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  display: block;
  padding: 5px 5px;
}
.date_field {
  text-align: center;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.date_container {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: space-between;
  margin-left: 10%;
}
.search_button {
  padding: 6px 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 1px;
  margin-left: 30px;
  color: #fff;
  background-color: #138496;
  border-color: #138496;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.NoDataFound{
  text-align: center;
  font-size: 22px;
  padding-top: 20px;
  margin-right: 400px;
}
.table_heading {
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  background-color: #138496;
  padding: 4px;
  border-left: 0.5px solid lightgrey;
  color: #fff;
}
.bulkhiring_heading{
  font-size: 32px;
  font-weight: 700;
  margin-left: 12px;
  margin-bottom: 48px;
}

.MainContainer{
  padding: 0 20px;  
  flex:4;
 background-color:#f5f7fb;
 /* padding: 8px 12px; */
 overflow: scroll;
 /* height: 97vh;  */
} 

.input_box{
margin-top: 30px;
background-color:#f5f7fb ;     
} 

.id_input{
    font-family: "PT Sans", sans-serif;
    font-size: 16px;
    display: block;
    padding: 5px 5px;
    width: 100%;
}


 .form_data {
      pointer-events: none;
      opacity: 1;
    }


.nameInputDiv{
  display: flex;
  padding: 10px , 20px;
  width : 100%

 }  

.userdetail_heading{
  font-size: 28px;
  font-weight: 600;
  margin-left: 5px;
}

.postARequirementLiveButtons{
    margin: 1rem;
    text-align: center;
}

/* .approve{
    background-color:lightgreen;
    color:white;
    font-size: 20px;
    border: none;
    outline:none;
    cursor: pointer;
    padding:8px 0px;
    border-radius: 8px;
    width: 110px;
  }

.disapprove{
    background-color:tomato;
    color:white;
    font-size: 20px;
    border: none;
    outline:none;
    cursor: pointer;
    padding:8px 0px;
    border-radius: 8px;
    margin: 1rem;
    width: 110px;
  } */




.display {
 display: flex;
  width: 100%;
  justify-content: space-between; 
  align-items: center;
  padding-top: 10px;
  /*margin-top: 15px;
   font-weight: 500; */
  
}
.detail_heading {
  font-weight: 600;
  padding: 10px 0;
  margin-top: 21px;
  font-size : 22px;
}
.image_url{
  text-decoration: none;
}
.titleBlock{
  color: #292e42;
  margin-left: 10px;
  margin-bottom: 25px; 
  font-size: 15px;
}

.titleHead{
  font-size: 17px; 
  font-weight: bold
}
.titleText{
  font-size: 16px;
  font-weight: 400;
  word-wrap:break-all;
}
.title{
  
  font-size: 15px; 
  width: inherit;
  font-weight: 500;
  line-height: 2;
}


.Company_Logo{
  font-weight:700;
  
}
.Company_Logodiv{
  margin-top: 10px;
  margin-left:5px ;
}
.title_text {
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  font-weight:300; 

}

.value{
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  font-weight: 200px;
}