.LandingPage_MainContainer {
  flex: 4;
  width: 93%;
  background-color: #f5f7fb;
  padding: 30px 50px;
}
.LandingPage_label {
  font-weight: 800;
  color: #292e42;
}
.LandingPage_IP {
  padding: 12px;
  border: 1px solid #ece9e9;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px 0px;
  width: 100%;
}
.button {
  background-color: #138496;
  color: white;
  border-radius: 6px;
  display: flex;
  margin: auto;
  padding: 10px 20px;
  margin-top: 25px;
  border: none;
}

.button:hover {
  background-color: #3d24af;
}
.btnDiv {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* laddingpage */

.MainContainer {
  overflow-x: scroll;
  overflow-y: scroll;
  flex: 4;
  background-color: #f5f7fb;
  padding: 8px 12px;
  height: 100vh;
}
.CardContainer {
  display: flex;
  flex-direction: row;
  margin: auto;
}
/* --------------SignupDetailsTable--------------- */
.signup_details_table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
  margin: auto;
}
.table_heading {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  background-color: #138496;
  padding: 7px;
  margin-top: 30px;
}
.table_td {
  text-align: center;
  padding: 7px;
  font-size: 16px;
  background-color: #ffffff;
  border-bottom: 0.5px solid #292929;
}
.table_container {
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
  margin:20px auto;
  height: 100vh;
}
.date_input {
  margin: 10px;
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  display: block;
  padding: 5px 5px;
}
.date_field {
  text-align: center;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-left: 50px;
}
.date_container {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: space-between;
  margin-left: 17px;
}

.MainContainer {
  flex: 4;
  background-color: #f5f7fb;
  padding: 8px 12px;
  overflow: scroll;
}
.search_button {
  padding: 6px 30px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
  color: #fff;
  background-color: #138496;
  border-color: #138496;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.add_button {
  padding: 6px 30px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 30px;
  color: #fff;
  background-color: #138496;
  border-color: #138496;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.search_button {
  padding: 6px 30px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  background-color: #138496;
  border-color: #138496;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.landingPage_heading{
  font-size: 32px;
  font-weight: 700;
  margin-left: 12px;
  margin-bottom: 48px;
}

