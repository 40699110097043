.accordion {
    max-width: 768px;
  }
  .accordion_item {
    list-style: none;
    padding-bottom: 10px;
  }
  .accordion_toggle {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #138496;
    color: #fff;
    padding: 1em;
    margin-bottom:10px ;
  }
  .accordion_toggle:hover {
    background-color: #138496;
  }
  .accordion_toggle h3 {
    margin: 0;
  }
  .accordion_content {
    background-color: #eee;
    padding: 1em;
  }