.MainContainer{
    flex:4;
    background-color:#f5f7fb;
    padding: 8px 12px;
    overflow: scroll;
}
.table {
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
    margin: auto;
  }
  .table_heading {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    background-color: #138496;
    padding: 5px;
    margin-top: 30px;
  }
  .table_td {
    text-align: center;
    padding: 7px;
    font-size: 16px;
    background-color: #ffffff;
    border-bottom: 0.5px solid #292929;
  }
  .table_container {
    border-radius: 5px;
    width: 96%;
    box-shadow: 0px 0px 5px 0px rgba(177, 129, 129, 0.75);
    margin: auto;
  }
  .button {
    background-color: #138496;
    color: white;
    width: 140px;
    border-radius: 6px;
    display: flex;
    margin: 10px 0px;
    padding: 8px 8px;
   justify-content: center;
   cursor: pointer;

  }

  .table_row{
    margin-top: 25px;  
  }
  .button_link{
    text-decoration: none;
    color: #fff;
  }