.MainContainer {
  width: 100%;
  background-color: #f5f7fb;
  
}
.date_container {
  display: flex;
  align-items: center;

}
.CardContainer {
  display: flex;
  flex-direction: row;
  margin: auto;
}
/* --------------SignupDetailsTable--------------- */
.signup_details_table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
  margin: auto;
}
.table_heading {
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  background-color: #a76b50;
  padding: 4px;
  border-left: 0.5px solid lightgrey;
  color: #fff;
}
.table_td {
  text-align: center;
  padding: 7px;
  font-size: 16px;
  background-color: #ffffff;
  border-bottom: 0.5px solid #292929;
  border-left: 0.5px solid lightgrey;
}
.table_container {
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
  margin: auto;
  overflow: scroll;
  height: 65vh;
  margin-top: 15px;
}
.date_input {
  margin: 10px;
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  display: block;
  padding: 5px 5px;
}
.date_field {
  
  font-size: 20px;
 
  margin-left: 30px;
}

.searchButton {

  background-color:#a76b50;
  margin-top: 10px;
  height: 20px;
  width: 50px;
  color: white;
  padding: 10px;
  border-radius: 3px;

 
}

.searchButton:hover{
cursor: pointer;
background-color: white;
border: 1px solid #a76b50;
color: #a76b50;;
}
.NoDataFound {

  font-size: 16px;
 margin-left: 5%;
 
 
}

.dashBoard_heading{
 
 
 

}
.dashBoard_heading_Container{
  background-color: #138496;
padding: 10px;
font-size: 20px;
font-weight: bold;  
}