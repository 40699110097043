.mainContainer {
    width: 100%;

}

.sectionContainer {
    padding-left: 30px;
    border: 1px solid rgb(223, 223, 223);
    padding: 10px;
    display: flex;
}

.containerWidthManage {
    width: 50%;
}

.SkillsSection {
    margin: 12px;
    display: flex;
    flex-wrap: wrap;
}

.langaugeSection {
    display: flex;
    flex-wrap: wrap;
}

.languages {
    padding-top: 5px;
}

.Skills {

    color: #5555e5;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 7px;
    margin-top: 10px;
    border: 1px solid #5555e5;

}

.heading {
    font-weight: bold;
    padding: 5px;
    word-wrap: break-word;
}

.response {
    font-weight: 500;
}

.tabSection {
    color: aqua;
    width: 100%;
}

.IscompanyTag {
    /* border: 1px solid gray; */
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
    background-color: #5555e5;
    color: white;
    font-size: 16px;
    width: fit-content;
}

.imgContainer {
    width: 100px;
    height: 100px;
}

.profile {
    width: 100%;
}

.mainHeading {
    font-size: 25px;
    font-weight: 800;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contentSectionLeft {
    padding: 20px;
    border: 1px solid rgb(215, 215, 215);
    align-items: center;
    margin: 10px
}

.userInfoContainer {
    display: flex;
    justify-content: space-around;
}