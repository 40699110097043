.SkillTab {
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px;
    font-size: 14px;
    border: 1px solid rgb(223, 223, 223);
  }
  .mainContainer {
    padding: 10px;
    background-color: white;
    margin: 10px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  }
  .tabContainer {
    display: flex;
    flex-wrap: wrap;
  }