.MainContainer{
    flex:4;
    background-color:#f5f7fb;
    padding: 8px 12px;
    overflow: scroll;
    height: 97vh;
}

.postARequirementDetailsHeading{
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline;
    margin-bottom: 2rem;
}
.table_container {
    border-radius: 5px;
    width: 96%;
    box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
    margin: auto;
  }
  .signup_details_table {
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
    margin: auto;
  }
  .table_heading {
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    background-color: #138496;
    padding: 4px;
    border-left: 0.5px solid lightgrey;
    color: #fff;
  }
  .table_td {
    text-align: center;
    padding: 7px;
    font-size: 16px;
    background-color: #ece9e9;
    border: 1px solid black;
    }
    .postARequirementLiveButtons{
        margin: 1rem;
        text-align: center;
    }
    .approve{
      background-color:lightgreen;
      color:white;
        font-size: 15px;
        border: none;
        outline:none;
        cursor: pointer;
        padding:10px;
        border-radius: 5px;
        margin: 1rem;
    }
    .disapprove{
      background-color:tomato;
        color:white;
        font-size: 15px;
        border: none;
        outline:none;
        cursor: pointer;
        padding:10px;
        border-radius: 5px;
        margin: 1rem;
    }