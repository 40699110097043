.mainContainer{
    padding: 10px;
    background-color: #ffffff;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
    margin: 2%;
}

.headingContent{
    font-size: 20px;
color:#a76b50;
font-weight: 600;


}