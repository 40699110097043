.MainContainer{
  flex:4;
  background-color:#f5f7fb;
  padding: 8px 12px;
  overflow: scroll;
}
.table {
  width: 100%;
  margin-top: 10px !important;
  border-collapse: collapse;
  margin: auto;
}
.table_heading {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  background-color: #138496;
  padding: 4px;
  border-left: 0.5px solid lightgrey;
  color: #fff;
}
.table_td {
  text-align: center;
  padding: 7px;
  font-size: 16px;
  background-color: #ffffff;
  border-bottom: 0.5px solid #292929;
  border-left: 0.5px solid lightgrey;
}
.table_container {
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
  margin: auto;
  overflow: scroll;
  height: 65vh;
  margin-top: 15px;
}
.date_input{
  margin: 10px;
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  display: block;
  padding: 5px 5px;
  margin-left: 16px;
  }
  .date_field {
    text-align: center;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-left: 60px
  }
  .date_container{
    display: flex;
    align-items: center;
  
     
     
      
  }
  .search_button{
    padding: 4px 30px;
  }
  .button {
    padding: 8px 30px;
    cursor: pointer;
    font-size: 18px;
    margin-left: 25px;
    color: #fff;
    background-color: #138496;
    border: 1px solid #138496;
    border-radius: 5px;
    font-weight: 400;
    text-align: center;

  }
  .button_link{
    padding: 10px 30px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 27px;
    margin-left: 180px;
    color: #fff;
    background-color: #138496;
    border-color: #138496;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
  .update_link{
    color: #3f9dff;
    text-decoration: none;
    cursor: pointer;
  }

  .date_input {
    margin: 10px;
    font-family: "PT Sans", sans-serif;
    font-size: 16px;
    display: block;
    padding: 5px 5px;
  }
  .date_field {
    text-align: center;
    font-size: 20px;
  }

  .search_button {
    padding: 6px 30px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #fff;
    background-color: #138496;
    border-color: #138496;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 0.25rem;
    margin-left:20px ;
  }
  .partnerpages_heading{
    font-size: 32px;
    font-weight: 700;
    margin-left: 12px;
    margin-bottom: 48px;
  }