.buttonForPassword{
    padding: 3px;
    background-color: orange;
    width: fit-content;
    border-radius: 3px;
    color: white;
    margin-left: 10px;
    font-size: 14px;
}
.buttonForPassword:hover{
cursor: pointer;
background-color:rgb(189, 123, 0);

}

 
   

.inputandButton{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  margin: 1%;


}
.errorMess{
    text-align: right;
    margin-right: 1%;
    color: red;
    font-weight: bold;
    font-size: 12px;
}
