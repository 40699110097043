.MainContainer {
  width: 100%;
}
.statusText{
  display: flex;
  justify-content: end;
}
.statusTextContainer{
  background-color:#a76b50;
  margin-top: 10px;
text-align: center;
  width: 120px;
  color: white;
  padding: 3px 10px;
  border-radius: 3px;
  font-size:20px;
  font-weight: 600;
}
.heading{
  font-weight: bold;
  padding: 5px;
  display: flex;
    align-items: center;
}
.userInfoContainer{
  display: flex;
  justify-content: space-around;
  width: 50%;
}

.mainHeading{
  font-size: 25px;
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contentSectionLeft{
  padding:10px 20px;
  border: 1px solid rgb(215, 215, 215);
  align-items: center;
  margin:10px
  }
  .reject{
    background-color:#fa0d0d;
    margin-top: 10px;
    text-align: center;
    width: 50px;
    color: white;
    padding: 3px 22px;
    border-radius: 6px;
    cursor: pointer;
  }
  .reject:hover{
    border: 1px solid #fa0d0d;
    background-color: #fff;
    color: #fa0d0d;
  }
  .approve{
    background-color:green;
    margin-top: 10px;
   text-align: center;
    width: 75px;
    color: white;
    padding: 3px 10px;
    border-radius: 6px;
    cursor: pointer;
  }
  .approve:hover{
    border: 1px solid green;
    background-color: #fff;
    color: green;
  }
  .buttonContainer{
    display: flex;
    width: 25%;
    justify-content: space-between;
    margin: 12px;
  }
  .inputCenter{
    padding: 6px;
    margin-top: 8px;
    font-size: 14px;
  }
  .response{
    font-weight: 500;
    margin-left: 10px;
   
  }
  .nameContainer{
    width: 100px;
    height: 100px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 30px;
    font-weight: 600;
    margin: 12px 0px;
  }

  .btnbtn{
    display: block;
    justify-content: space-evenly;
    margin: 10px 1%;

  }
  .Rejected{
    font-size: large;
      font-weight: 700;
  }
  .Approved{
    font-size: large;
    font-weight: 700;
  }