.mainContainer {
    width: 100%;
   
}
.sectionContainer{
    padding-left: 30px;
    border: 1px solid rgb(223, 223, 223);
    padding: 10px; 
    display: flex;
}
.containerWidthManage{
    width: 50%;
}
.heading{
    font-weight: bold;
    padding: 5px;
}
.response{
    font-weight: 500;
}
.tabSection{
    color: aqua;
    width: 100%;
}
.IscompanyTag{
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
    background-color:#5555e5;
    color: white;
    font-size: 16px;
    width: fit-content;
    margin-top: 10px;
}
.imgContainer{
    width: 100px;
    height: 100px;
}
.profile{
    width: 100%;
}
.mainHeading{
    font-size: 25px;
    font-weight: 800;
    margin-top: 10px;
    margin-bottom: 10px;
}
.contentSectionLeft{
padding: 20px;
border: 1px solid rgb(215, 215, 215);
align-items: center;
margin:10px
}
.userInfoContainer{
    display: flex;
    justify-content: space-around;
}
 