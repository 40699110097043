body{
    background-color: #f0f0f0;
}
.logoImage{
    margin: auto;
    /* width: 30%;/ */
    font-size: small;
    height: 80px;
    display: flex;
}

.login_form {
    background-color: #fff;
    border: 1px solid #eee;
    margin-left: 50%;
    position: absolute;
    margin-top: 23%;
    transform: translate(-50%, -50%);
    border: 5px solid #FFFF00;
    padding: 25px 100px 25px 100px;
    border: 1px solid #dfdfdf;
}

.Or{
    font-size: 24px;
    font-weight: 700;
    margin-left: 200px;
}

.signIn {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #858585;
    text-align: center;
}

.other_signIn{
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #858585;
    text-align: center;
}

.login_form_fld {
    text-align: center ;
    margin-bottom: 25px;
}

label {
    font-size: 17px;
}

.login_field{
    width: 100%; 
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 10px;
}

.login_btn{
    color: #fff;
    background-color: #0d6efd;
    border: 1px solid #0d6efd;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    padding: 10px;
    width: 80%;
    font-size: 17px;
    cursor: pointer;
    border-radius: 10px;
}

.google_signIn{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    padding: 10px;
}

.gsin {
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    padding: 30px;
    border-radius: 2px;
    font-weight: 600;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    border: none;
    text-align: center;
    background: none;
    width: 100%;
    font-size: 18px ;
}
.gsin span {
    padding: 10px 35px 10px 35px;
    font-weight: 600;
    margin-left: 50px;
}

.signUp_link{
    display: block;
    color: #858585;
    text-align: center;
    margin-top: 15px;
    text-decoration: none;
    cursor: pointer;
}