.pageLayout{
    width: 100%;
    margin: 20px;
    
}
.tabSection{
    background-color: #F1F1F3;
    margin-top: 20px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.tabHeading{
    font-weight: 600;
    margin: 5px;
    
}
.tabHeading:hover{
    cursor: pointer;
    
}
.selectedTab{
    border-bottom: 3px solid #A66B50;
    font-weight: 600;
    margin: 5px;
}
.TransactionInfo_margin {
    margin-top: 20px;
}