.MainContainer{
    flex:4;
    background-color:#f5f7fb;
    padding: 30px 50px;  
}
.buttonBox {
    background-color: #7e7c7c;
    color: white;
    border-radius: 6px;
    display: flex;
    margin: auto;
    padding: 10px 20px;
    margin-top: 25px;
  border: none;
  cursor: pointer;
  }
  .radioinput {
    padding: 10px 0px;
}
.lableDesign {
    font-weight: 800;
    margin-bottom: 7px;
    color: #292e42;
  }
  .table_heading {
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    background-color: #138496;
    padding: 4px;
    border-left: 0.5px solid lightgrey;
    color: #fff;
    
  }