.MainContainer {
  flex: 4;
  background-color: #f5f7fb;
  padding: 8px 12px;
  width: 93%;
}
.CardContainer {
  display: flex;
  flex-direction: row;
  margin: auto;
}
/* --------------SignupDetailsTable--------------- */
.signup_details_table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
  margin: auto;
}
.table_heading {
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  background-color: #138496;
  padding: 4px;
  border-left: 0.5px solid lightgrey;
  color: #fff;
}
.table_td {
  text-align: center;
  padding: 7px;
  font-size: 16px;
  background-color: #ffffff;
  border-bottom: 0.5px solid #292929;
  border-left: 0.5px solid #292929;
}
.table_container {
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
  margin: auto;
  overflow: scroll;
  padding-top: 25px;
  height: 57vh;
}
.date_input {
  margin: 10px;
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  display: block;
  padding: 5px 5px;
}
.date_field {
  text-align: center;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.date_container {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: space-between;
  margin-left: 17%;
}
.search_button {
  padding: 6px 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 1px;
  margin-left: 20px;
  color: #fff;
  background-color: #138496;
  border-color: #138496;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.NoDataFound{
  text-align: center;
  font-size: 22px;
  padding-top: 20px;
  margin-left: 10px;
  margin-right: 60px;
}
.reportABug_heading{
  font-size: 32px;
  font-weight: 700;
  margin-left: 12px;
  margin-bottom: 48px;
}
.Img{
  height: 50px;
  width: 50px;
  border-radius: 50%;
}