.mainContainer {
    padding: 10px;
}

.reqBox {
    border: 1px solid rgb(213, 213, 213);
    margin: 10px;
    padding: 10px;
    display: flow-root;
    justify-content: space-between;
}

.reqBoxContainer {
    display: flex;
    justify-content: space-between;
}

.HeadContainer {
    margin: 12px;
    display: flex;
    justify-content: space-between;
}

.titleHeadContainer {
    margin: 12px;
}

.heading {
    font-weight: bold;
    padding: 5px;
    width: 30%;
}

.logo {
    margin-left: 5px;
}

.BudgetHeading {
    margin-top: 60px;
    padding-top: 60px;
    margin-right: 10px;
}

.LeftContainer {
    width: 74%;
}

.RightContainer {
    width: 26%;
    text-align: right;
}

.StatusContainer {
    padding: 15px;
}

.statustabcontainer {
    color: orange;
    font-weight: bold;
}

.statustabcontainerapprove {
    color: green;
    font-weight: bold;
}

.RequirmentStatus {
    background-color: orange;
    color: white;
    font-size: 16px;
    padding: 9px 15px;
    border-radius: 5px;
    margin-left: 7px;
}

.RequirmentStatuspaid {
    background-color: green;
    color: white;
    font-size: 16px;
    padding: 9px 15px;
    border-radius: 5px;
    margin-left: 7px;
}

.logo {
    font-weight: bold;
    width: 50px;
}

.date {
    padding: 5px;
    font-weight: bold;
}

.skillHeading {
    font-weight: bold;
    margin-left: 10px;
}

.SkillsSection {
    display: flex;
    margin: 12px;
}

.Skills {
    display: flex;
    flex-wrap: wrap;
}

.tabcontainer {
    color: #5555e5;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 7px;
    margin-top: 10px;
    border: 1px solid #5555e5;
}

.proposalHeading {
    font-weight: bold;
    padding: 5px;
    margin-left: 3px;
}

.ProposalContainerSection {
    border-bottom: 1px solid;
    margin-top: 5px;
    padding: 5px 0px 12px 0px;
}

.ProposalSection {
    padding-left: 10px;
}

.mainHeading {
    font-weight: 700;
    margin-left: 5px;
}

.ProposalLengthSection {
    display: flex;
    align-items: center;
}

.ProposalLengthText {
    font-size: 16px;
    background-color: #5555e5;
    padding: 8px 12px;
    color: #fff;
    margin: 10px 0px 0px 18px;
}

.Buttons {
    margin-left: 35px;
    position: relative;
}

.msg {
    font-weight: 600;
}

.IsOnsiteTag {
    font-weight: bold;
    margin: 13px;
}

.modalButtons {
    margin-top: 30px;
    position: relative;
    display: flex;
    justify-content: center;
}

.approve {
    background-color: #04AA6D;
    color: white;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 9px 10px;
    border-radius: 5px;
}

.disapprove {
    background-color: #CC202E;
    color: white;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 9px 15px;
    border-radius: 5px;
    margin: 10px;
}

.yesBtn {
    background-color: #04AA6D;
    color: white;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 8px;
    margin: 12px;
}

.noBtn {
    background-color: #CC202E;
    color: white;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 8px;
    margin: 12px;
}

.modalinput {
    border-radius: 3px;
    width: 90%;
    height: 25px;
    margin-top: 30px;
    border: 1px solid;
    outline: none;
}

.ErrorMessage {
    color: #CC202E;
    text-align: left;
    margin-left: 15px;
    margin-top: 2px;
}

.Btnloder {
    background-color: #04AA6D;
    color: white;
    margin: 12px;
    padding: 10px 30px;
    border-radius: 8px;
}

.Approved {
    color: green;
}

.Draft {
    color: #fea11b;
}

.Submitted {
    color: #365aa9;
}

.Disapproved {
    color: #CC202E;
}

.edit_button {
    background-color: #365aa9;
    color: white;
    font-size: 16px;
    margin: 12px;
    padding: 10px 30px;
    border-radius: 5px;
    border: none;
}

.float_button {
    float: right;
}