.MainContainer {
  width: 100%;
  background-color: #f5f7fb;
  overflow: scroll;
}
.CardContainer {
  display: flex;
  flex-direction: row;
  margin: auto;
}
 
.signup_details_table, .Bulk_table,.ClientProfile_table,.Kyc_table,.Profile_table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
  margin: auto;
}

.table_heading {
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  background-color: #a76b50;
  padding: 4px;
  border-left: 0.5px solid lightgrey;
  color: #fff;
}
.table_td {
  text-align: center;
  padding: 7px;
  font-size: 16px;
  background-color: #ffffff;
  border-bottom: 0.5px solid #292929;
  border-left: 0.5px solid lightgrey;
} 
.table_container {
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgb(255 255 255 / 75%);
  padding: 10px;
  overflow: scroll;
  margin-top: 15px;

  border-collapse: collapse;
  border-spacing: 0;
}
.date_input {
  margin: 10px;
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  display: block;
  padding: 5px 5px;
}
.date_field {

  font-size: 20px;
  display: flex;
  align-items: center;
 
}
.date_container {
  display: flex;
margin-left: 3%;
  align-items: center;
}
.searchButton {
  background-color:#a76b50;
 height: 20px;
  width: 45px;
  color: white;
  padding: 10px;
  border-radius: 3px;
 margin-left: 15px;
 text-align: center;
}

.searchButton:hover{
cursor: pointer;
background-color: white;
border: 1px solid #a76b50;
color: #a76b50;;
}
.searchButton_Link {
  background-color:#a76b50;
 height: 20px;
  width: 50px;
  color: white;
  padding: 10px;
  border-radius: 3px;
 margin-left: 5px;
}

.NoDataFound {
font-size: 16px;
margin-left: 3%;
}
.dashBoard_heading_Container{
  background-color: #138496;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;  
  }

  .recordLength{
      margin-left: 5%;
      display: flex;
      align-items: center;
      width: 100%;
  }
  .Tagsection{
    display: flex;
    justify-content: space-between;
  }
  .Tag{
    background-color: #138496;
   padding: 2px 6px;
   border-radius: 8px;
   color: #fff;
   font-size: 12px;
   width: fit-content;
  }
.searchBarContainer {
  display: flex;
  justify-content: center;
  width: 40%;
  margin: auto;
}

.searchInput {
  padding: 3px 0px;
  width: 110%;
  margin-top: 13px;
 
  padding: 12px 10px 11px 10px;
  font-size: 18px;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid #a76b50;
}
.searchInput:focus {
  outline: none;
  border-right-width: 0px;
}
.searchInput::placeholder {
  font-size: 18px;
  color: rgb(197, 197, 197);
}
.searchIcon {
  padding: 13px 14px 14px 14px;

background-color: #a76b50;
  color: white;
  margin-top: 13px;
  display: flex;
  align-items: center;
  border-radius: 0px 4px 4px 0px;
}
.searchIcon:hover {
  cursor: pointer;
  background-color:#a76b50 ;
  color: white;
}
.buttonContainer{
margin-top: 20px;
text-align: center;
margin-right: 100px;
}

.NoDataFound{
  text-align: center;
  font-size: 20px;
  margin-top: 5%;
  font-weight:400 ;
  
}

.PaginationContainer {
  padding: 10px 22px 0px 22px;
  text-align: right;
  
}
.buttonone {
  color: #fff;
  background-color: #a76b50;
  padding: 8px 19px;
  border: none;
  margin-left: 4px;
  margin-right: 8px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}
.CurrentPageText{
  border: 1px solid #a76b50;
  border-radius: 8px;
  padding: 3px 10px;
  width: 30px;
  margin-right: 5px;
  color: #1778f2;
  margin-left: 8px;
}
.CurrentPageNext{
  border: 1px solid #a76b50 ;
  border-radius: 8px;
  padding: 3px 10px;
  width: 30px;
  margin-right: 5px;
  color: #1778f2;
  margin-left: 8px;
  cursor: pointer;
}
.CurrentPageTextNext{
  border: 1px solid #a76b50;
  border-radius: 8px;
  padding: 3px 10px;
  width: 30px;
 cursor: pointer;
}

.buttononePre{
 color: gray;
  font-size: 15px;
  border: 1px solid #a76b50;
  border-radius: 5px;
  padding: 3px 14px; 
  margin-top: 6px;
  margin-left: 3px;
  cursor: pointer;
}

.table_td_link{
text-align: center;
padding: 7px;
font-size: 16px;
background-color: #ffffff;
border-bottom: 0.5px solid #292929;
border-left: 0.5px solid lightgrey;
text-decoration: underline;
cursor: pointer;
}

.skill_container{
color: #5555e5;
font-size: 16px;
padding: 5px 10px;
border-radius: 5px;
margin-left: 7px;
margin-top: 10px;
border: 1px solid #5555e5;
}

.choosetype{
margin-left: 30px;
margin-top: 10px;
}
.button_delete {

border: none;
color: white;
padding: 10px 20px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 15px;
}

.chatBox{
padding: 10px;
margin: 10px 10px 10px 15px;
}

.freelancermsgBox{
border-radius: 10px;
background-color: bisque;
margin: 10px;
padding: 8px;
width: 35%;
border-radius: 22px 5px 25px 1px;
}

.messageClient{
word-wrap: break-word;
}

.messageFreelancer{
word-wrap: break-word;
}

.clientmsgBox{
border-radius: 10px;
background-color: rgb(231, 228, 225);
margin: 10px;
padding: 8px;
margin: 0px 10px 10px auto;
}

.freelancertxthead{
color: gray;
font-size: 12px;
}

.Clienttxthead{
color: gray;
font-size: 12px;
text-align: right;
}
.clientmsgBox{
text-align: right;
width: 35%;
border-radius: 6px 12px 0px 30px;
}

.BoxCover{
width: 100%;
}

.head{
width: 90%;
font-size: 22px;
margin: auto;
font-weight: 700;
}

.mainContainerChat{
  width: 90%;
overflow-x: scroll;
height: 100vh;
}
.choose{
  display: flex;
}

.MainContainer{
  flex:4;
  background-color:#f5f7fb;
  padding: 8px 12px;  
}
/*.CardContainer{
  display: flex;
  flex-direction: row;
  margin: auto;
} */
.ArticleHeading{
  font-weight: 500;
  padding: 7px;
  margin: 0;
  background-color:#a76b50;
  color: #fff;
}
.imageDiv{
display: flex;
}
.multipleimages{
width: 40%;
height: 80px;
margin-right: 10px;
margin-bottom: 10px;
}

.checkboxDiv{
display: flex;
flex-wrap: wrap;
flex-direction: column;
margin-top: 20px;
}
.closeIcon{
margin-left: -9px;
margin-top: -2px;
cursor: pointer;
}
.copyIcon{
margin-left: -17px;
margin-top: 42px;
cursor: pointer;

}
.dropDownClass{
padding: 100px;
}

.ArticleHeading_others{
  font-weight: 700;
  padding: 10px 0px;
  margin: 0;
}

.left{
  flex: 5;
  padding: 10px 25px;
  overflow-y: auto;
  max-height: 80vh;
}
.categories{
padding: 5px;
margin-left: 10px;
background-color: grey;
border-radius: 5px;
margin-top: 10px;

}
.deleteCategoryIcon{
color: #fff;
margin-left: 5px;
}
.mapDiv{
display: flex;
flex-wrap: wrap;
}

.right{
  flex: 3;
  padding: 10px 25px;
  overflow-y: auto;
  max-height: 80vh;

  border-left: 2px solid rgb(145, 145, 145);
}

.form_container{

      display: flex;
      height: 100%;
      background-color: rgb(216, 216, 216);
    
}
.formeditor{
    margin-top: 10px;
}

.formeditor label {
  font-weight: 600;
  color: #000000;
  width: 100vw;
}
.formeditor select {
  padding: 5px 5px;
  height: 25px;
  border-radius: 4px;
  background-color: #727272;
  color: #fdfdfd;
  width: 98%;
  margin-top: 10px;
  outline:none;
}
.formeditor input {
  padding: 5px 5px;
  height: 25px;
  border-radius: 4px;
  background-color: #f3f3f3;
  color: #363636;
  width: 98%;
  border: 1px solid #000000;
  outline:none;
  margin-top: 10px;
 

}

.formeditor textarea {
  padding: 5px 5px;
  resize: none;
  overflow-y: auto;
  outline:none;
  display: block;
  width: 100%;
  border-radius: 4px;
  background-color: #efefef;
  color: #fdfdfd;
  border: 1px solid #000000;
  margin-top: 10px;
  color: #363636;
}

.formeditor input[name="tags"] {
  width: 98%;
}


.form_error_msg_captcha {
  color: #f02f39;
  font-size: 12px;
  text-align: start;
  font-style: italic;
  margin-top: 5px;
}

.author_name{
line-break: anywhere;
width:200px;
}
.author{
font-size: 14px;
font-weight: 700;
border:1px solid rgb(119, 119, 119);
line-break: break-word;
margin: auto;
align-items: center;
text-align: center;
display:block;
}
.del{
font-size:22px;
cursor: pointer !important;
}
.content {

  border: 1px solid grey;
  overflow-y: auto;

  margin-top: 10px;
}
.bottom_del_flex{
  display: flex;
}
.submitButton{
    margin: 20px auto;
    display: block;
    border: none;
    border-radius:4px;
    padding:10px 20px;
    cursor: pointer;
    background-color:rgb(255, 47, 47);

    color: #fdfdfd;;
   
}
.submitButton:hover{
  background-color: green
}

.button_author{
  border:none;
  padding:5px 20px;
  color: #ffffff;
  background-color:rgb(72, 189, 57);
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
float: right;
cursor: pointer;
margin-right: 5px;
  
 
}
.button_author_delete{
border:none;
padding:5px 20px;
color: #ffffff;
background-color:rgb(255, 47, 47);
margin-top: 15px;
margin-bottom: 10px;
border-radius: 4px;
float: right;
cursor: pointer;
}

.box_author{
  background-color: #c5c5c5;
  border: 2px solid grey;
  padding: 0px 20px 13px 10px;
  margin-top: 25px;
}
.articlemultipleImagea{
  display: flex;
 
  align-items: center;
  margin-top:10px;
  overflow: hidden;
 flex-wrap: wrap;

}

.image_top{
  width: 70px;
  margin-left: 15px;
  margin-bottom:10px;
  height: 55px;
}

.image[type=text]{
  width:100%;
  border:2px solid #aaa;
  border-radius:4px;
  margin:8px 0;
  outline:none;
  padding:8px;
  background-color:white;
  box-sizing:border-box;
  transition:.3s;
}
.image[type=text]:focus{
  border-color:dodgerBlue;
  box-shadow:0 0 8px 0 dodgerBlue;
}

.date_input{
  cursor:pointer;
}
.date_input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

  .createArticle_heading{
    font-size: 32px;
  font-weight: 700;
  margin-left: 12px;
  margin-bottom: 12px;
  }

  .MainContainer{
    flex:4;
    background-color:#f5f7fb;
    padding: 8px 12px;  
    height: 96vh;
}

.FormContainer {
    display: flex;
    height: 95%;
    background-color: rgb(216, 216, 216);;
  }
  .left{
    flex: 5;
    padding: 10px 15px;
  }
  .right{
    flex: 3;
    padding: 10px ;
    border-left: 2px solid rgb(145, 145, 145);
  }

  /* .formGroup {
    margin: 10px;
  } */
  
  .formGroup_TextTitle{
    font-size: 15px;
    color:  #000000;
  }

  .date_article{
    color:  #000000;
  }


.Img_Artical{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.otherDetailHeading {
  border-bottom: 3px solid #747474;
  padding-bottom: 5px;
  color:  #000000;
  font-size: 17px;
}

.artical_date{
  color:  #000000;
}

.artical_date_content{
  margin-top: 10px;
}
.sub_heading{
  font-weight: 600;
  font-weight: 500;
}
.sub_heading_bottom{
  font-weight: 600;
}
.short{
  padding-top: 5px;
}


   .update_link{
    background-color: blue; 
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
   }
   .MainContainer{
    flex:4;
    background-color:#f5f7fb;
    padding: 8px 40px;  
}
.lableDesign {
  font-weight: 700;
  color: #292e42;
  margin-bottom: 7px;
  margin-left: 2px;
  margin-top: 10px;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: auto;
}
.inputArea:focus {
  outline: none;
}
.inputArea {
  padding: 12px;
  border: 1px solid #ece9e9;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px 0px;
  font-size: 15px;
}
.buttonBox {
    background-color: #008CBA;
    color: white;
    border-radius: 6px;
    display: flex;
    margin: auto;
    padding: 10px 20px;
    margin-top: 25px;
    border: none;
  }

  .boxRow{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;    
  }

  .inputBox{
    display: block;
    font-weight: 600;
  }
  .box{
    margin: 2%;
  }
  .inputUpdate:focus {
    outline: none;
  }
  .inputUpdate {
    padding: 12px;
    border: 1px solid #ece9e9;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px 0px;
    font-size: 15px;
    width: 90%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
  }
  

  .form_upload_label{
    height: 20px;
    width: 20px;
  }
  .uploadIcon{
    height: 20px;
    width: 20px;
  }
  .uploadPlaceholder{
    color: gray;

  }
  .personalInfoInputEdit {
    margin: 25px 0px;
  }
  .personalInfoInputState {
    margin: 35px 0px;
  }
  .editButton {
    cursor: pointer;
    margin-left: 5px;
  }
  .editButton:hover {
    color: #1678f2;
  }
  .inputBoxContainer{
    display: flex;
    justify-content: space-between;
    width: 90%;
    border: 1px solid gray;
    border-radius: 8px;
    margin: 20px 0px 20px 0px;
    padding: 8px;
  }
  .inputBoxone{
    display: block;
    font-weight: 600;
  }
 
  .inputUpdated:focus {
    outline: none;
  }
  .inputUpdated {
    padding: 12px;
    border: 1px solid #ece9e9;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px 0px;
    font-size: 15px;
    width: 93%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
  }
  .htmlEditor{
    margin-top: 10px;
    width: 93%;
  }