.backdrop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.modal,
.modalSkills {
  position: fixed;
  z-index: 20;
  background: #fff;
  height: 156px;
  width: 350px;
  border-radius: 12px;
  padding: 5px;

  
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.icon{
  float: right;
  margin: 8px;
}
.modalSkills {
  height: 260px;
}

.ModalProfBox,
.ModalCreditBox,
.ModalBrainBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ModalCreditBox,
.ModalBrainBox {
  margin: 30px 30px;
  text-align: center;
}

.ModalBrainBox {

  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.modalmainText {
  text-align: center;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  margin-top:25px ;
}
.inputbox{
  width: 200px;
  margin-left: 55px;
  border: 1px solid #b1b1b1;
  padding: 6px;
  margin-top: 8px;
  border-radius: 8px;
}

.modalcheck {
  height: 30px;
  width: 30px;
  border: 1px solid;
  padding: 20px;
  margin-top: 10px;
  border-radius: 50%;

  background-color: green;
  color: #fff;

  border-color: green;
}

.headText {
  margin-bottom: 0px;
}

.ModalCheckTextSecond {
  text-align: center;
  margin: 16px 0px;
  font-size: 16px;
  font-weight: 700;
}

.ModalCheckIcon {
  margin: 5px 0px;
  margin-bottom: 0px;
}

.crossicon {
  float: right;
}

.ActionBtn {
  display: flex;
  width: 230px;
  justify-content: space-between;
}

.ActionBtnSkills {
  text-align: center;
}

.ModalCredTextFirst {
  margin-top: 14px;
}


.okcancel {
  display: flex;
  color: white;
  margin: 20px 77px;
}

.ok {
    padding: 5px;
    background-color: green;
    width: 60px;
    border-radius: 10px;
    font-weight: 600;
    text-align: center;
    margin-right: 25px;
    cursor: pointer;
}
.cancel {
  padding: 5px;
  background-color: red;
  width: 60px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
}




/* .overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
  }
  .popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  .popup h4 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
  }
  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .close:hover {
    cursor: pointer;
    color: #000;
  }
  .popup .content {
    max-height: 30%;
    overflow: auto;
    text-align: center;
  }
  .box{
    border: 1px solid black;
    width: 30%;
      height: 150px;
      border-radius: 12px;
      background-color: #dfdfdf;
  }
  .okcancel{
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
  }
     .backdrop {
       position: fixed;
       top: 0;
       left: 0;
       width: 100%;
       height: 100vh;
       z-index: 20;
       background-color: rgba(0, 0, 0, 0.75);
     }
 .ok{
    padding: 5px;
      background-color: green;
      width: 60px;
      display: flex;
      justify-content: center;
      border-radius: 10px;
      font-weight: 600;
 }
    .headcontainer{
      font-weight: 600;
       
    }
    .input{
      margin-left: 55px;
      width: 80px;
      text-align: center;
      display: flex;
    }
    .inputbox{
      width: 200px;
    }
  .head{
display: flex;
  justify-content: center;
  margin-top: 30px;
  }
.cancel {
  padding: 5px;
    background-color: red;
    width: 60px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    font-weight: 600;
}   
.overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.75);
      display: flex;
      align-items: center;
    }
    .popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  .popup h4 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
  }
  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .close:hover {
    cursor: pointer;
    color: #000;
  }
  .popup .content {
    max-height: 30%;
    overflow: auto;
    text-align: center;
  }
  @media screen and (max-width: 700px) {
    .popup {
      width: 70%;
    }
  } */
    .popup {
      margin: 70px auto;
      padding: 20px;
      background: #fff;
      border-radius: 5px;
      width: 30%;
      position: relative;
      transition: all 5s ease-in-out;
    }
  
    .popup h4 {
      margin-top: 0;
      color: #333;
      font-family: Tahoma, Arial, sans-serif;
    }
  
    .popup .close {
      position: absolute;
      top: 20px;
      right: 30px;
      transition: all 200ms;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;
      color: #333;
    }
  
    .popup .close:hover {
      cursor: pointer;
      color: #000;
    }
  
    .popup .content {
      max-height: 30%;
      overflow: auto;
      text-align: center;
    }
    .overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
  }
  .popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  .popup h4 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
  }
  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .close:hover {
    cursor: pointer;
    color: #000;
  }
  .popup .content {
    max-height: 30%;
    overflow: auto;
    text-align: center;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
  }